<template>
  <div
    :id="id"
    class="wa-product-carousel card_carousel"
    :class="modifierClass"
  >
    <h4 v-if="title">{{ title }}</h4>
    <div>
      <button
        v-if="!hideArrows && page !== 1"
        class="prev card_carousel__action"
        @click="previousPage"
      >
        Prev
      </button>
      <div
        :ref="`${id}-scroll-grid`"
        class="carousel-scroll-grid"
        @scroll="updatePage"
      >
        <ul :ref="`${id}-grid`" class="product-grid card_carousel__grid">
          <slot />
        </ul>
      </div>
      <button
        v-if="!hideArrows && page !== pageCount"
        class="next card_carousel__action"
        @click="nextPage"
      >
        Next
      </button>
    </div>
    <div v-if="!hidePagination" class="carousel-pager">
      <ul class="pagination">
        <li
          v-for="index in pageCount"
          :key="index"
          :class="{ current: index === page }"
        >
          <span v-if="index === page" class="show-for-sr">You're on page</span>
          <button @click="scrollToPage(index)">{{ index }}</button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    hidePagination: {
      type: Boolean,
      default: false,
    },
    hideArrows: {
      type: Boolean,
      default: false,
    },
    itemCount: {
      type: Number,
      required: true,
    },
    maxPageSize: {
      type: String,
      default: '3',
    },
    extraClasses: {
      type: String,
      default: '',
    },
    oneFourthWidthCard: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      page: 1,
      perPage: parseInt(this.maxPageSize),
      maxPages: 10,
    }
  },
  computed: {
    pageCount() {
      return Math.min(this.maxPages, Math.ceil(this.itemCount / this.perPage))
    },
    modifierClass() {
      return `card_carousel__col card_carousel__col--${this.maxPageSize} ${this.extraClasses}`
    },
  },
  mounted() {
    window.addEventListener('resize', this.adjustCarouselSize)
    this.adjustCarouselSize()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.adjustCarouselSize)
  },
  methods: {
    adjustCarouselSize() {
      const width = window.innerWidth
      if (width <= 640) {
        this.perPage = 1
      } else if (width <= 1024) {
        this.perPage = 2
      } else if (width <= 1440 && !this.oneFourthWidthCard) {
        this.perPage = 3
      } else {
        this.perPage = parseInt(this.maxPageSize)
      }
    },
    previousPage() {
      this.scrollToPage(this.page - 1)
    },
    nextPage() {
      this.scrollToPage(this.page + 1)
    },
    scrollToPage(page) {
      if (!this.$refs[`${this.id}-scroll-grid`]) {
        return
      }
      const scrollWidth = this.$refs[`${this.id}-scroll-grid`].scrollWidth
      const itemWidth = scrollWidth / Math.max(1, this.itemCount)
      const pageWidth = itemWidth * this.perPage
      this.$refs[`${this.id}-scroll-grid`].scrollLeft = (page - 1) * pageWidth
    },
    updatePage() {
      if (!this.$refs[`${this.id}-scroll-grid`]) {
        this.page = 1
        return
      }
      const itemWidth =
        this.$refs[`${this.id}-scroll-grid`].scrollWidth /
        Math.max(1, this.itemCount)
      const pageWidth = itemWidth * this.perPage
      // Round to the nearest 10th (2.3) and then round up
      // Adjusts for slight discrepancies in scroll distance when pages are
      // uneven
      this.page =
        Math.ceil(
          Math.trunc(
            (10 * this.$refs[`${this.id}-scroll-grid`].scrollLeft) / pageWidth
          ) / 10
        ) + 1
    },
  },
}
</script>
